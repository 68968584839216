import { TABLE_NAMES, ISSUE_DOCUMENTS_TYPES, ISSUE_DOCUMENT_SIZE_LIMIT_IN_BYTES } from "@/constants"
import { getFootersForTable } from "@/utils/table"
import ThemisDecision from "@/components/shared/decision"
import ThemisInput from "@/components/shared/input"
import { MAX_CHARACTER_LIMIT, FILE_TYPE } from "@/constants"
import { ITEM_COMPARISON } from "@/constants"

export default {
  name      : "IssueDocuments",
  components: {
    ThemisDecision,
    ThemisInput
  },
  props: {
    pIssueId                      : Number,
    pDocumentsOfReport            : Array,
    pDocumentsOfIssue             : Array,
    pUsersMap                     : Object,
    pIssueDocumentRemovePolicies  : Array,
    pIssueAddDocumentsPolicy      : Object,
    pIsAddingIssueDocument        : Boolean,
    pIsIssueDocumentAdded         : Boolean,
    pIsRemovingIssueDocument      : Boolean,
    pIsIssueDocumentRemoved       : Boolean,
    pIsReportAnonymised           : Boolean,
    pIsAddingFolder               : Boolean,
    pIsFolderAdded                : Boolean,
    pIsUpdatingFolder             : Boolean,
    pIsFolderUpdated              : Boolean,
    pIssueAddFoldersPolicy        : Object,
    pIsRemovingFolder             : Boolean,
    pIsFolderRemoved              : Boolean,
    pFolders                      : Array,
    pIsMovingToFolder             : Boolean,
    pIsMovedToFolder              : Boolean,
    pIssueDocumentUpdatePolicies  : Array,
    pMessageItemUpdatePolicies    : Array,
    pFolderUpdatePolicies         : Array,
    pFolderRemovePolicies         : Array,
    pIsUpdatingDocumentDescription: Boolean,
    pIsDocumentDescriptionUpdated : Boolean
  },
  data: () => ({
    expanded                 : [],
    currentFolder            : null,
    documentToBeRemoved      : undefined,
    folderNameCharacterLimit : MAX_CHARACTER_LIMIT.FOLDER_NAME,
    showRemoveDocumentDialog : false,
    descriptionCharacterLimit: MAX_CHARACTER_LIMIT.FILE_DESCRIPTION,
    showAddFolderDialog      : false,
    showDeleteFolderDialog   : false,
    breadCrumbsItems         : [],
    showMoveToFolderDialog   : false,
    itemToMove               : undefined,
    selectedFolderForMove    : undefined,
    folderName               : null,
    folderEditMode           : false,
    localFolder              : null,
    isDescriptionFocused     : false,
    currentActiveFolder      : []
  }),
  computed: {
    canAddDocuments() {
      return this.pIssueAddDocumentsPolicy?.set?.add !== undefined
    },
    canAddFolder() {
      return this.pIssueAddFoldersPolicy?.set?.add !== undefined
    },
    allowedIssueDocumentsTypes() {
      return Object.values(ISSUE_DOCUMENTS_TYPES)
    },
    issueId() {
      return this.pIssueId
    },
    getBreadCrumbs() {
      if (!this.breadCrumbsItems.length) {
        this.breadCrumbsItems.push({
          text    : this.$t("1967", { issueId: this.pIssueId }),
          disabled: true
        })
      }
      if (this.currentFolder !== null) {
        const folder                    = this.pFolders.find(pFolder => pFolder.id === this.currentFolder)
        const folderExistsInBreadCrumbs = this.breadCrumbsItems.find(breadCrumb => breadCrumb.id === folder.id)
        if (!folderExistsInBreadCrumbs) {
          this.breadCrumbsItems.forEach(item => item.disabled = false)
          this.breadCrumbsItems.push({
            id      : folder.id,
            text    : folder.name,
            disabled: true
          })
        }
      }
      return this.breadCrumbsItems
    },
    expandedFolders() {
      const folderIdToReveal     = this.itemToMove.folderId
      const expandedFoldersArray = this.
        locateFolderPathToTargetFolder(this.cascadedFolders, folderIdToReveal, [])

      this.currentActiveFolder   = [expandedFoldersArray[expandedFoldersArray.length - 1]]
      this.selectedFolderForMove = expandedFoldersArray[expandedFoldersArray.length - 1]
      return expandedFoldersArray
    },
    isMoveToActionAllowed() {
      return !this.selectedFolderForMove || (this.itemToMove.folderId !== this.selectedFolderForMove.id)
    },
    documentsForDisplaying() {
      const documentsForDisplaying = new Array()
      if (this.pFolders) {
        for (const folder of this.pFolders) {
          documentsForDisplaying.push({
            id                   : folder.id,
            key                  : `${FILE_TYPE.FOLDER}-${folder.id}`,
            name                 : folder.name,
            createdAt            : this.$moment(folder.createdAt).format("DD MMMM YYYY HH:mm (UTCZ)"),
            createdBy            : this.pUsersMap[folder.creatorId]?.name,
            color                : "",
            folderId             : folder.parentId,
            isFolder             : true,
            showDocumentsMenu    : false,
            disabledButNotDeleted: this.pUsersMap[folder.creatorId]?.disabledButNotDeleted
          })
        }
      }
      if (this.pDocumentsOfIssue) {
        for (const document of this.pDocumentsOfIssue) {
          let createdBy
          let disabledButNotDeleted
          const isDocumentRemoved = !document.name
          const user              = this.pUsersMap[document.creatorId]
          if(user) {
            createdBy             = user.name,
            disabledButNotDeleted = user.disabledButNotDeleted
          }
          documentsForDisplaying.push({
            id                  : document.id,
            key                 : `${FILE_TYPE.DOCUMENT}-${document.id}`,
            name                : isDocumentRemoved ? this.$tc("783") : document.name,
            createdAt           : this.$moment(document.createdAt).format("DD MMMM YYYY HH:mm (UTCZ)"),
            isReportDocument    : false,
            fileExists          : document.fileExists,
            color               : "",
            type                : this.$t("656"),
            showDocumentsMenu   : false,
            addOrShowDescription: this.getAddOrShowDescriptionValue(document),
            addDescriptionDialog: false,
            description         : document.description,
            localDescription    : null,
            isFolder            : false,
            folderId            : document.folderId,
            disabledButNotDeleted,
            createdBy,
            isDocumentRemoved
          })
        }
      }
      if (this.pDocumentsOfReport) {
        for (const document of this.pDocumentsOfReport) {
          const isDocumentRemoved = !document.value
          documentsForDisplaying.push({
            id                  : document.id,
            key                 : `${FILE_TYPE.REPORT_DOCUMENT}-${document.id}`,
            name                : isDocumentRemoved ? this.$tc("783") : document.value.name,
            createdBy           : this.$t("1000"),
            createdAt           : this.$moment(document.createdAt).format("DD MMMM YYYY HH:mm (UTCZ)"),
            isReportDocument    : true,
            color               : "primary lighten-4",
            type                : this.$t("657"),
            fileExists          : document.fileExists,
            showDocumentsMenu   : false,
            addOrShowDescription: this.getAddOrShowDescriptionValue(document),
            addDescriptionDialog: false,
            localDescription    : null,
            isFolder            : false,
            folderId            : document.folderId,
            description         : document.description,
            isDocumentRemoved
          })
        }
      }
      return documentsForDisplaying
    },
    headersForTable() {
      return this.$TABLES.ISSUE_DOCUMENTS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.ISSUE_DOCUMENTS, this.$t.bind(this))
    },
    foldersMap() {
      const foldersMap = new Object()
      for (const folder of this.pFolders) {
        foldersMap[folder.id] = { ...folder, children: [] }
      }
      return foldersMap
    },
    cascadedFolders() {
      const cascadedFolders = [{
        id      : null,
        name    : this.$t("1967", { issueId: this.pIssueId }),
        parentId: null,
        children: []
      }]

      for (const folder of this.pFolders) {
        const parent       = this.foldersMap[folder.parentId]
        const folderToPush = this.foldersMap[folder.id]
        if (parent) {
          parent.children.push(folderToPush)
        } else {
          cascadedFolders[0].children.push(folderToPush)
        }
      }
      return cascadedFolders
    },
    foldersForDisplaying() {
      let selectedItemChildrenDepth = 0
      if (this.itemToMove.isFolder) {
        const currentSelectedItemInTreeNode = this.currentSelectedItemTreeNode(this.cascadedFolders)
        selectedItemChildrenDepth           = this.calculateMaxDepthOfFolder(currentSelectedItemInTreeNode)
      }
      return this.disableFolderNodes(this.cascadedFolders, this.itemToMove, selectedItemChildrenDepth, 0)
    },
    currentDocumentFolderPath() {
      const folderId   = this.itemToMove.folderId ? this.itemToMove.folderId : this.itemToMove.parentId
      const rootFolder = this.$t("1967", { issueId: this.pIssueId })
      if (!folderId) return rootFolder
      return `${rootFolder} / ${this.getPathOfDocument(folderId).join( " / ")}`
    },
    disableAddOrRenameFolderButton() {
      if (this.folderEditMode) {
        return this.folderName === this.localFolder.name
      }
      return !this.folderName
    },
    hasFolders() {
      return this.pFolders.length
    }
  },
  methods: {
    getAddOrShowDescriptionValue(documentToFine) {
      return !!(this.expanded.find(document => document.id === documentToFine.id))?.addOrShowDescription
    },
    currentSelectedItemTreeNode(cascadedFolders) {
      for (const cascadedFolder of cascadedFolders) {
        if (cascadedFolder.id === this.itemToMove.id) {
          return cascadedFolder
        }
        if (cascadedFolder.children) {
          const foundFolder = this.currentSelectedItemTreeNode(cascadedFolder.children)
          if (foundFolder) {
            return foundFolder
          }
        }
      }
      return null
    },
    calculateMaxDepthOfFolder(folder, currentDepth = 1) {
      if (!folder.children || folder.children.length === 0) {
        return currentDepth
      }
      let maxDepth = currentDepth
      for (const child of folder.children) {
        maxDepth = Math.max(maxDepth, this.calculateMaxDepthOfFolder(child, currentDepth + 1))
      }

      return maxDepth
    },
    disableFolderNodes(items, selectedItem, selectedItemChildrenDepth, currentLevel) {
      const itemToMoveIsFolder = selectedItem.isFolder
      items.forEach(item => {
        const isItemAt4thLevel           = currentLevel >= 4 && itemToMoveIsFolder
        const isSelectedItemIsSame       = item.id === selectedItem.id && itemToMoveIsFolder
        const isSelectedItemDepthExceeds = (selectedItemChildrenDepth + currentLevel) > 4
        if (( isItemAt4thLevel || isSelectedItemIsSame  || isSelectedItemDepthExceeds) ) {
          item.disabled = true
        }
        if (item.children && item.children.length > 0) {
          this.disableFolderNodes(item.children, selectedItem, selectedItemChildrenDepth, currentLevel + 1)
        }
      })
      return items
    },
    handleClickOnBreadcrumb(item) {
      if (item.text === this.$t("1967", { issueId: this.pIssueId })) {
        this.currentFolder    = null
        this.breadCrumbsItems = [{
          text    : this.$t("1967", { issueId: this.pIssueId }),
          disabled: true
        }]
      } else {
        this.currentFolder = this.pFolders.find(folder => folder.id === item.id).id
        if (this.breadCrumbsItems.length > 1) {
          const index           = this.breadCrumbsItems.findIndex(breadCrumb => breadCrumb.id === item.id)
          this.breadCrumbsItems = this.breadCrumbsItems.slice(0, index)
        }
      }
    },
    getDocumentsForDisplaying() {
      let documents
      if (this.currentFolder) {
        documents = this.documentsForDisplaying.filter(document => document.folderId === this.currentFolder)
      } else {
        documents = this.documentsForDisplaying.filter(document => !document.folderId)
      }
      this.$emit("update-document-count", documents.filter(document => !document.isFolder).length)
      return documents
    },
    menuItems(document) {
      return  [{
        condition: this.isDescriptionButtonDisplayed(document),
        title    : this.$t("1943"),
        icon     : "mdi-text",
        class    : "info-lighten-4",
        action   : () => this.handleClickOnMenuDescription(document)
      }, {
        condition: !document.isDocumentRemoved && !document.isFolder,
        title    : this.$t("654"),
        icon     : "mdi-download-outline",
        class    : "info-lighten-4",
        action   : () => this.download(document)
      }, {
        condition: this.canMoveFolder(document) && this.pFolders.length,
        title    : this.$t("2021"),
        icon     : "mdi-folder-move-outline",
        class    : "info-lighten-4",
        action   : () => this.handleClickOnMenuMoveTo(document)
      }, {
        condition: document.isFolder && this.canUpdateFolder(document.id),
        title    : this.$t("1970"),
        icon     : "mdi-pencil-outline",
        class    : "info-lighten-4",
        action   : () => this.renameFolder(document)
      }, {
        condition: this.isDeleteButtonDisplayed(document),
        title    : this.$t("655"),
        icon     : "mdi-trash-can-outline",
        class    : document.isFolder || document.isReportDocument ? "info-lighten-4" : "error--text",
        action   : () => this.openRemoveDocumentDecision(document)
      }].filter(item => item.condition)
    },
    handleClickOnMenuMoveTo(document) {
      this.itemToMove             = document
      this.showMoveToFolderDialog = true
    },
    handleClickOnMenuDescription(document) {
      if (document.addOrShowDescription) {
        this.expanded = this.expanded.filter(expandedDocument => expandedDocument.id !== document.id)
      } else {
        document.addOrShowDescription = !document.addOrShowDescription
        this.expanded.push(document)
      }
    },
    cancelDescription(document) {
      document.addDescriptionDialog = false
      document.localDescription     = null
    },
    handleClickOnDocumentsMenu(document) {
      document.showDocumentsMenu = !document.showDocumentsMenu
    },
    handleCancelMoveToFolder() {
      this.showMoveToFolderDialog = false
      this.itemToMove             = undefined
      this.selectedFolderForMove  = undefined
    },
    isSaveDescriptionEnabled(document) {
      const { description, localDescription } = document
      if (description === localDescription) {
        return false
      }
      if (!localDescription) {
        return description?.length > 0
      }
      return localDescription.trim()?.length > 0
    },
    saveDescription(document) {
      if (document.type === this.$t("656")) {
        this.$emit("updateIssueDocument", {
          id         : document.id,
          description: document.localDescription
        })
      } else {
        this.$emit("updateReportDocument", {
          id         : document.id,
          description: document.localDescription
        })
      }
      document.localDescription     = document.localDescription.trim()
      document.addDescriptionDialog = false
      document.description          = document.localDescription
    },
    handleClickOnDescriptionView(document) {
      if (this.canAddOrUpdateDescription(document)) {
        document.addDescriptionDialog = true
      }
      document.localDescription = document.description
    },
    handleClickOnItem(document) {
      if (document.isFolder) {
        this.currentFolder = document.id
      }
    },
    downloadAll() {
      for (const document of this.pDocumentsOfIssue) {
        if (document.fileExists) {
          this.$emit("downloadIssueDocument", document)
        }
      }
      if (!this.pIsReportAnonymised) {
        for (const document of this.pDocumentsOfReport) {
          this.$emit("downloadReportDocument", document)
        }
      }
    },
    download(document) {
      if (!this.isMenuItemDisabled(document, { title: this.$t("654") })) {
        if (document.isReportDocument) {
          this.$emit("downloadReportDocument", this.pDocumentsOfReport.find(documentFromReporter => documentFromReporter.id === document.id))
        } else {
          this.$emit("downloadIssueDocument", this.pDocumentsOfIssue.find(documentFromUser => documentFromUser.id === document.id))
        }
      }
    },
    openFileExplorerToSelectDocuments() {
      this.$refs.input_add_documents.click()
    },
    onDocumentSelection(event) {
      for (const file of event.target.files) {
        if (file.size < ISSUE_DOCUMENT_SIZE_LIMIT_IN_BYTES) {
          this.$emit("addDocument", { file, folderId: this.currentFolder })
        }
      }
    },
    openRemoveDocumentDecision(documentToBeRemoved) {
      if (!this.isMenuItemDisabled(documentToBeRemoved, { title: this.$t("655") })) {
        this.documentToBeRemoved      = documentToBeRemoved
        this.showRemoveDocumentDialog = true
      }
    },
    closeRemoveDocumentDecision() {
      this.showRemoveDocumentDialog = false
      this.documentToBeRemoved      = undefined
    },
    documentRemoveDecisionSubTitle(documentToBeRemoved) {
      return documentToBeRemoved?.isFolder ? this.$t("2028") : this.$t("660")
    },
    documentRemoveDecisionText(documentToBeRemoved) {
      return documentToBeRemoved?.isFolder ? this.$t("2029") : this.$t("661")
    },
    canRemoveDocument(documentId) {
      const documentRemovePolicy = this.pIssueDocumentRemovePolicies.find(issueDocumentRemovePolicy =>
        issueDocumentRemovePolicy.id === documentId
      )
      return documentRemovePolicy?.set?.remove !== undefined
    },
    canRemoveFolder(folderId) {
      const folderRemovePolicy = this.pFolderRemovePolicies.find(folderRemovePolicy =>
        folderRemovePolicy.id === folderId
      )
      return folderRemovePolicy?.set?.remove !== undefined
    },
    canUpdateFolder(folderId) {
      const folderUpdatePolicy = this.pFolderUpdatePolicies.find(folderUpdatePolicy =>
        folderUpdatePolicy.id === folderId
      )
      return folderUpdatePolicy?.set?.name !== undefined
    },
    canMoveFolder(document) {
      if (document.isFolder) {
        const folderUpdatePolicy = this.pFolderUpdatePolicies.find(folderUpdatePolicy =>
          folderUpdatePolicy.id === document.id
        )
        return folderUpdatePolicy?.set?.parentId !== undefined
      } else if (document.isReportDocument) {
        const messageItemUpdatePolicy = this.pMessageItemUpdatePolicies.find(messageItemUpdatePolicy =>
          messageItemUpdatePolicy.id === document.id
        )
        return messageItemUpdatePolicy?.set?.folderId !== undefined
      } else {
        const issueDocumentUpdatePolicy = this.pIssueDocumentUpdatePolicies.find(issueDocumentUpdatePolicy =>
          issueDocumentUpdatePolicy.id === document.id
        )
        return issueDocumentUpdatePolicy?.set?.folderId !== undefined
      }
    },
    canAddOrUpdateDescription(document) {
      if (document.isReportDocument) {
        const messageUpdatePolicy = this.pMessageItemUpdatePolicies.find(messageUpdatePolicy =>
          messageUpdatePolicy.id === document.id
        )
        return messageUpdatePolicy?.set?.description !== undefined
      } else if (!document.isFolder) {
        const issueDocumentUpdatePolicy = this.pIssueDocumentUpdatePolicies.find(issueDocumentUpdatePolicy =>
          issueDocumentUpdatePolicy.id === document.id
        )
        return issueDocumentUpdatePolicy?.set?.description !== undefined
      }
    },
    isDescriptionButtonDisplayed(document) {
      if (!document?.isFolder) {
        return this.canAddOrUpdateDescription(document) || !!document?.description?.length || this.pIsIssueDocumentAdded
      }
    },
    isDeleteButtonDisplayed(document) {
      if (document?.isFolder) {
        return this.canRemoveFolder(document.id)
      } else {
        if (document.isReportDocument) {
          return true
        } else {
          return this.canRemoveDocument(document.id) && !document.isDocumentRemoved
        }
      }
    },
    isMenuItemDisabled(document, menuItem) {
      if (menuItem.title === this.$t("654")) {
        if (document.isReportDocument) {
          return false
        }
        return !this.pDocumentsOfIssue.find(issueDocument => issueDocument.id === document.id)?.fileExists
      }
      if (menuItem.title === this.$t("655")) {
        if (document.isReportDocument) {
          return true
        }
        if (document?.isFolder && !this.isFolderEmpty(document.id)) {
          return true
        }
      }
      return false
    },
    tooltipValue(document) {
      if (!document.isReportDocument) {
        if (document?.isFolder && !this.isFolderEmpty(document.id)) {
          return this.$t("2024")
        }
        const documentExists = this.pDocumentsOfIssue.find(
          issueDocument => issueDocument.id === document.id)?.fileExists
        if (!documentExists) {
          return this.$t("1942")
        }
      } else {
        return this.$t("2025")
      }
    },
    handleCancelAddFolder() {
      this.folderName          = null
      this.localFolder         = null
      this.folderEditMode      = false
      this.showAddFolderDialog = false
    },
    isFolderEmpty(folderId) {
      return !this.documentsForDisplaying.some(document => document.folderId === folderId)
    },
    handleSelectedFolder(selectedFolder) {
      if (selectedFolder.length) {
        this.selectedFolderForMove = selectedFolder[0]
        this.currentActiveFolder   = [this.selectedFolderForMove]
      }
    },
    handleMoveToFolder() {
      if (this.itemToMove.isFolder) {
        this.$emit("updateFolder", {
          id      : this.itemToMove.id,
          parentId: this.selectedFolderForMove.id
        })
      } else if(this.itemToMove.isReportDocument) {
        this.$emit("updateReportDocument", {
          id      : this.itemToMove.id,
          folderId: this.selectedFolderForMove.id
        })
      } else {
        this.$emit("updateIssueDocument", {
          id      : this.itemToMove.id,
          folderId: this.selectedFolderForMove.id
        })
      }
    },
    getPathOfDocument(id) {
      const folder = this.foldersMap[id]
      if (!folder) return []
      if (folder.parentId === null) return [folder.name]
      return [...this.getPathOfDocument(folder.parentId), folder.name]
    },
    renameFolder(folder) {
      this.folderName          = folder.name
      this.localFolder         = folder
      this.folderEditMode      = true
      this.showAddFolderDialog = true
    },
    handleAddOrRenameFolder() {
      const data = {
        name: this.folderName
      }
      if (this.folderEditMode) {
        this.$emit("updateFolder", {
          id: this.localFolder.id,
          ...data
        })
      } else {
        if (this.currentFolder) {
          data.parentId = this.currentFolder
        }
        this.$emit("addFolder", data)
      }
    },
    handleRemove(itemToBeRemoved) {
      if(itemToBeRemoved?.isFolder) {
        this.$emit("removeFolder", itemToBeRemoved.id)
      } else {
        this.$emit("removeDocument", itemToBeRemoved)
      }
    },
    customSort(items, sortBy, sortDesc) {
      const compareItemsBySortKey = (firstItem, secondItem, sortKey, sortOrder) => {
        // Needs to lowercase, because the sort is case-sensitive
        const firstValue  = typeof firstItem[sortKey] === "string" ? firstItem[sortKey].toLowerCase() : firstItem[sortKey]
        const secondValue = typeof secondItem[sortKey] === "string" ? secondItem[sortKey].toLowerCase() : secondItem[sortKey]

        if (firstValue < secondValue) return sortOrder
        if (firstValue > secondValue) return -sortOrder
        return 0
      }

      return items.sort((firstItem, secondItem) => {
        // Sort folders first
        if (firstItem.isFolder && !secondItem.isFolder) return -1
        if (!firstItem.isFolder && secondItem.isFolder) return 1

        // Sort by the specified column
        for (let sortKeyIterator = 0; sortKeyIterator < sortBy.length; sortKeyIterator++) {
          const sortKey   = sortBy[sortKeyIterator]
          const sortOrder = sortDesc[sortKeyIterator]
            ? ITEM_COMPARISON.FIRST_ITEM_IS_LOWER
            : ITEM_COMPARISON.FIRST_ITEM_IS_HIGHER

          const comparisonResult = compareItemsBySortKey(firstItem, secondItem, sortKey, sortOrder)
          if (comparisonResult) return comparisonResult
        }

        return 0
      })
    },
    locateFolderPathToTargetFolder(folders, targetFolderId, currentFolderPath) {
      for (const folder of folders) {
        const currentPathArrayWithFolders = [...currentFolderPath, folder]

        if (folder.id === targetFolderId) {
          return currentPathArrayWithFolders
        }

        if (folder.children && folder.children.length) {
          const targetFolderPath = this.
            locateFolderPathToTargetFolder(folder.children, targetFolderId, currentPathArrayWithFolders)

          if (targetFolderPath) {
            return targetFolderPath
          }
        }
      }
      return null
    }
  },
  watch: {
    pIsIssueDocumentRemoved: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.$emit("documentRemoved", this.documentToBeRemoved)
          this.showRemoveDocumentDialog = false
          this.documentToBeRemoved      = undefined
        }
      }
    },
    pIsRemovingIssueDocument: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_DOCUMENT.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_DOCUMENT.pActions[1].buttonProps.loading  = newValue
      }
    },
    pIsFolderAdded: {
      handler: function(newValue) {
        if (newValue) {
          this.showAddFolderDialog = false
          this.folderName          = null
        }
      }
    },
    pIsFolderUpdated: {
      handler: function(value) {
        if (value) {
          this.folderName          = null
          this.localFolder         = null
          this.folderEditMode      = false
          this.showAddFolderDialog = false
          this.$emit("folderUpdated")
        }
      }
    },
    pIsFolderRemoved: {
      handler: function(value) {
        if (value) {
          this.showRemoveDocumentDialog = false
          this.$emit("folderRemoved", this.documentToBeRemoved)
          this.documentToBeRemoved = undefined
        }
      }
    },
    pIsRemovingFolder: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_DOCUMENT.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_DOCUMENT.pActions[1].buttonProps.loading  = newValue
      }
    },
    pIsMovedToFolder: {
      handler: function(newValue) {
        if (newValue) {
          this.showMoveToFolderDialog = false
          this.$emit("movedToFolder", this.itemToMove)
          this.itemToMove            = undefined
          this.selectedFolderForMove = undefined
        }
      }
    },
    issueId: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.breadCrumbsItems = []
          this.currentFolder    = null
        }
      }
    }
  }
}